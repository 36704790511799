import { candidate as candidateService } from "../../services";
import { STEPS } from "../../pages/AssmntStart/flow";
const __Terminated = STEPS.Terminated.slug

const modifyStateMap = {
  "flowControl/markAssessmentStepComplete": (state, action) => {
    const stepKey = action.payload;
    state.assessment.progress[stepKey].completed = true;
    return state;
  },
  "flowControl/markTestStepComplete": (state, action) => {
    const stepKey = action.payload;
    state.currentTest.progress[stepKey].completed = true;
    return state;
  },
  "flowControl/markTestComplete": (state) => {
    state.assessment.tests.currTestIndex += 1;
    state.currentTest.index += 1;
    state.currentTest.__initialized = false;
    return state;
  },
  "flowControl/markQuestionComplete": (state, action) => {
    state.currentTest.currAQIndex += 1;
    state.currentTest.isSaving = false;
    return state;
  },
  "flowControl/appendNextQuestionGroup": (state, action) => {
    const latestInvite = action.payload;
    const currentTestIndex = state.currentTest.index;
    const actualQuestions = latestInvite.tests[currentTestIndex].questions;
    const answeredQuestions = actualQuestions.filter(
      (q) => q.attemptStatus === "ANSWERED"
    );

    state.assessment.__data = latestInvite;
    state.currentTest.actualQ = actualQuestions;
    state.currentTest.totalAQ = actualQuestions.length;
    state.currentTest.currAQIndex = answeredQuestions.length;
    return state;
  },
  "flowControl/terminateAssessment": (state, action) => {
    const progress = state.assessment.progress
    for(let key in progress){
      if(progress[key].completed) continue
      progress[key].skipped = Boolean(key !== __Terminated)
    }
    state.assessment.progress = progress
    return state
  },
};

export const flowControlSyncToDB = (store) => (next) => async (action) => {
  console.log(`______________Action: ${action.type}________________`);
  const state = store.getState().flowControl;
  if (modifyStateMap[action.type]) {
    const _state = JSON.parse(JSON.stringify(state));
    console.log(_state)
    const payload = modifyStateMap[action.type](_state, action);
    await candidateService
      .updateFlowControl(payload)
      .catch((err) => console.log(err));
  }
  next(action);
};

export const STEPS = {
  Test_GuideLine: {
    slug: "Test_GuideLine",
  },
  Practice_Question: {
    slug: "Practice_Question",
  },
  Test_Start_Alert: {
    slug: "Test_Start_Alert",
  },
  Actual_Queston: {
    slug: "Actual_Queston",
  },
  Test_Complete: {
    slug: "Test_Complete",
  },
};

export const QTYPES = {
  MULTIPLE_CHOICE: "MULTIPLE_CHOICE",
};

export const TestProgressDTO = {
  [STEPS.Test_GuideLine.slug]: {
    completed: false,
    skip: false,
  },
  [STEPS.Practice_Question.slug]: {
    completed: false,
    skip: false,
  },
  [STEPS.Test_Start_Alert.slug]: {
    completed: false,
    skip: false,
  },
  [STEPS.Actual_Queston.slug]: {
    completed: false,
    skip: false,
  },
  [STEPS.Test_Complete.slug]: {
    completed: false,
    skip: false,
  },
};

export const getTestProgressDTO = ({ hasPracticeQ = false, isFirstTest }) => {
  return {
    [STEPS.Test_GuideLine.slug]: {
      completed: false,
      skip: false,
    },
    [STEPS.Practice_Question.slug]: {
      completed: false,
      skip: false,
    },
    [STEPS.Test_Start_Alert.slug]: {
      completed: !isFirstTest,
      skip: !isFirstTest,
    },
    [STEPS.Actual_Queston.slug]: {
      completed: false,
      skip: false,
    },
    [STEPS.Test_Complete.slug]: {
      completed: false,
      skip: false,
    },
  };
};
